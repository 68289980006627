


















import { defineComponent, reactive, onMounted } from '@vue/composition-api'

import { SectionHeader } from 'components'
import { api } from 'plugins'
import { endpoints } from 'utils'
import { MENU } from './menu'

interface SubItem {
  title: string
}
interface Item {
  title: string
  children: Array<SubItem>
}
interface SetUp {
  menus: Array<Item>
  logout: () => void
}

const Menu = defineComponent({
  components: {
    SectionHeader
  },
  props: {},
  setup(props, { root }): SetUp {
    const menus: Array<Item> = reactive(MENU)
    const logout = (): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const customerRoot = root as any
      customerRoot.$auth.logout()
    }

    onMounted(() => {
      api.get(endpoints.TEST).then((response) => {
        console.log(response.data)
      })
      api.get(endpoints.ITEMS).then((response) => {
        console.log(response.data)
      })
    })

    return {
      menus,
      logout
    }
  }
})
export default Menu
